<template>
  <div id="app">
    <CusHeader :categoryMap="categoryMap"></CusHeader>
    <div id="content">
      <div ref="content-wrap" class="content-wrap">
        <div class="page-wrap" v-loading="loading">
          <keep-alive>
            <!-- :key="$route.fullPath" -->
            <router-view
              :key="$route.fullPath"
              v-if="!loading && $route.meta.keepAlive !== false"
              :categoryMap="categoryMap"
              :tagsMap="tagsMap"
            />
          </keep-alive>
          <router-view
            v-if="$route.meta.keepAlive === false"
            :categoryMap="categoryMap"
            :tagsMap="tagsMap"
          />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import CusHeader from "@/components/Header";
import { menuData } from "@/components/Header/config";
import Footer from "@/components/Footer";
import { debounce, flatMapDeep } from "lodash";
import { getTags, getNewCmsCatalogList } from "@/api/index";

export default {
  name: "App",
  components: {
    CusHeader,
    Footer,
  },
  data: () => ({
    loading: true,
    isDev: process.env.ENV === "development",
    scrollPx: {},
    categoryMap: {},
    tagsMap: [],
  }),
  watch: {
    $route() {
      this.$nextTick(() => {
        this.$refs["content-wrap"].scrollTop =
          this.scrollPx[this.$route.path] || 0;
      });
    },
  },
  mounted() {
    this.$refs["content-wrap"].addEventListener("scroll", () => {
      this.handleSetScrollPx();
    });
    Promise.all([this.handleGetCmsCatalogList(), this.handleGetTags()]).finally(
      () => {
        this.loading = false;
      }
    );
  },
  methods: {
    /* 记录当前route滚动的位置，切换的时候滚动到该位置 */
    handleSetScrollPx: debounce(function () {
      this.scrollPx[this.$route.path] = this.$refs["content-wrap"].scrollTop;
    }, 300),
    async handleGetTags() {
      try {
        const res = await getTags();
        const flattened = flatMapDeep(menuData, (item) => {
          if (item.children) {
            return [item, ...item.children];
          } else {
            return item;
          }
        });
        const tagsMap = res.map((item) => {
          return {
            title: item.value,
            value: item.label,
            tags: item.children.map((tag) => tag.value),
          };
        });
        this.tagsMap = flattened.reduce((acc, cur) => {
          if (cur.tagGroup) {
            acc[cur.path] = tagsMap.filter((item) =>
              cur.tagGroup.includes(item.title)
            );
          }
          return acc;
        }, []);
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    },
    async handleGetCmsCatalogList() {
      try {
        const res = await getNewCmsCatalogList();
        const flattenedMenu = flatMapDeep(menuData, (item) => {
          if (item.children) {
            return [item, ...item.children];
          } else {
            return item;
          }
        });
        const flattenedCate = flatMapDeep(res.rows, (item) => {
          if (item.children) {
            return [item, ...item.children];
          } else {
            return item;
          }
        });
        this.categoryMap = flattenedCate.reduce((acc, cur) => {
          const findItem = flattenedMenu.find(
            (item) => item.title === cur.label
          );
          if (!findItem) return acc;
          acc[findItem.path] = {
            id: cur.id,
            title: cur.label,
            catalogType: cur.props.catalogType,
            redirectUrl: cur.props.redirectUrl,
          };
          return acc;
        }, {});
        console.log(this.categoryMap);
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f9f9;
  overflow: hidden;
}
#content {
  flex: 1;
  min-width: 1200px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.content-wrap {
  height: 100%;
  overflow: overlay;
}
.page-wrap {
  min-height: calc(100% - 68px);
  height: max-content;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 9px;
  height: 8px;
  background-color: #f7f7f7;
  border-radius: 32px;
  visibility: hidden;
  position: absolute;
  &:hover {
    visibility: visible;
  }
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 8px;
  background: #a19b9b;
  border-radius: 32px;
  visibility: hidden;
  position: absolute;
  &:hover {
    visibility: visible;
  }
}
/* .top-banner-fixed {
  height: 40px;
  position: fixed;
  top: 60px;
}
.top-banner {
  height: 220px;
  position: absolute;
  transition: all 0.1s;
} */
</style>
