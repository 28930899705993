export const menuData = [
  {
    title: "首页",
    path: "/home/index",
  },
  {
    title: "税收数字化能力",
    path: "/tax",
    children: [
      {
        title: "区域财源治理",
        path: "/tax-area",
        tagGroup: ["服务领域", "面向行业", "服务对象"],
      },
      {
        title: "税收现代化",
        path: "/tax-modern",
        tagGroup: ["服务领域", "面向行业", "服务对象"],
      },
      {
        title: "产业招商",
        path: "/tax-investment",
        tagGroup: ["服务领域", "面向行业", "服务对象"],
      },
      {
        title: "数据资产入表",
        path: "/tax-data",
        tagGroup: ["服务领域", "面向行业", "服务对象"],
      },
    ],
  },
  {
    title: "案例数据资源",
    path: "/case",
    children: [
      {
        title: "政策法规库",
        path: "/case-law",
        tagGroup: ["税种分类", "地区", "关键词"],
      },
      {
        title: "实务案例库",
        path: "/case-practice",
        tagGroup: ["案例类型", "编写方式", "关键词", "面向行业"],
      },
      {
        title: "行业数据库",
        path: "/case-industry",
      },
    ],
  },
  {
    title: "数据工具箱",
    path: "/tool",
    tagGroup: ["服务领域", "面向行业", "服务对象"],
  },
  {
    title: "课程活动",
    path: "/course",
    tagGroup: ["服务领域", "面向行业", "服务对象"],
  },
  {
    title: "联盟合作",
    path: "/alliance",
    tagGroup: ["合作对象", "合作方向", "合作内容"],
  },
  {
    title: "专家团队",
    path: "/expert",
    tagGroup: ["专家类型", "服务领域", "面向行业"],
  },
];
