<template>
  <div class="bottom-corp">
    All Rights Reserved 2024 by 税收数字化实训基地 | 备案号:鲁ICP备09006057号-7
    | ICP证：鲁B2-20180217 | 备案号:鲁公网安备 37090202000110号
  </div>
</template>
<script>
export default {
  name: "FooterBar",
};
</script>
<style lang="less" scoped>
.bottom-corp {
  height: 68px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  justify-content: center;
  box-shadow: 0px -1px 0px 0px #ebebeb;
}
</style>
