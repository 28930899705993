<template>
  <div class="header-container">
    <div class="header">
      <div class="header-title-container">
        <div class="header-title-logo" />
        <!-- <div class="header-title">税收数字化实训基地</div>
      <div class="header-desc">山东数字经济研究基地</div> -->
        <!-- <div class="header-desc">
          <i class="el-icon-caret-bottom" />
          <span>山东区域</span>
        </div> -->
      </div>
      <div class="header-menu-container">
        <div class="active-line" ref="activeLine"></div>
        <div
          class="menu-item"
          v-for="item in menuList"
          :key="item.path"
          ref="menuItem"
          @click="delayMenuClick($event, item.path)"
        >
          <div
            class="menu-item-title"
            :class="{
              'menu-item-title-active': $route.fullPath.includes(item.path),
            }"
          >
            {{ item.title }}
          </div>
          <div class="menu-item-children" v-if="item.children">
            <div
              class="menu-item-child"
              v-for="child in item.children"
              :key="child.path"
              @click="delayMenuClick($event, child.path)"
              :class="{
                'menu-item-child-active': $route.fullPath.includes(child.path),
              }"
            >
              {{ child.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="header-action">
        <RightContent></RightContent>
      </div>
    </div>
  </div>
</template>
<script>
import RightContent from "../RightContent";
import _ from "lodash";
import { menuData } from "./config";

export default {
  name: "CusHeader",
  components: {
    RightContent,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    categoryMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menuList: menuData,
    };
  },
  mounted() {
    this.handleActiveChange();
    window.addEventListener("resize", this.handleActiveChange);
  },
  watch: {
    "$route.fullPath"() {
      this.handleActiveChange();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleActiveChange);
  },
  methods: {
    /* 800ms内点击立马执行一次，且800ms最多执行一次 */
    delayMenuClick: _.throttle(
      function (e, path) {
        this.handleMenuClick(e, path);
      },
      800,
      { leading: true, trailing: false }
    ),
    handleMenuClick(e, path) {
      e.stopPropagation();
      if (path === this.$route.fullPath || this.$route.fullPath.includes(path))
        return;
      if (
        this.categoryMap[path] &&
        this.categoryMap[path].catalogType === "link" &&
        this.categoryMap[path].redirectUrl
      ) {
        window.open(this.categoryMap[path].redirectUrl, "_blank");
      } else {
        this.$router.push(path);
      }
    },
    handleActiveChange() {
      this.$nextTick(() => {
        if (this.$refs.menuItem.length === 0) return;
        /* baseLeft是第一个元素对父元素的左侧距离  */
        const baseLeft = this.$refs.menuItem[0].offsetLeft;
        const activeIndex = this.menuList.findIndex((item) => {
          let path = item.path;
          if (item.path === "/home/index") {
            path = "/home";
          }
          return this.$route.fullPath.includes(path);
        });
        if (activeIndex === -1) {
          this.$refs.activeLine.style.left = "-195px";
          this.$refs.activeLine.style.width = "68px";
          this.$refs.activeLine.style.borderRadius = "50%";
          return;
        }
        this.$refs.activeLine.style.borderRadius = "0";
        this.$refs.activeLine.style.height = "3px";
        const activeItem = this.$refs.menuItem[activeIndex];
        const sumWidth = this.menuList.reduce((sum, item, index) => {
          if (index < activeIndex) {
            sum += this.$refs.menuItem[index].offsetWidth;
          }
          return sum;
        }, 0);
        this.$refs.activeLine.style.left = `${
          baseLeft + sumWidth + 40 * activeIndex
        }px`;
        this.$refs.activeLine.style.width = `${activeItem.offsetWidth}px`;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header-container {
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
.header {
  height: 60px;
  margin: 0 auto;
  width: 1200px;
  flex-shrink: 0;
  background-color: #fff;
  display: flex;
  position: relative;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .header-title-container {
    width: 255px;
    justify-content: space-between;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 4px;
    user-select: none;
    .header-title-logo {
      width: 173px;
      flex-shrink: 1;
      height: 23px;
      background-image: url("@/assets/logo-title.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    // .header-title {
    //   height: 14px;
    //   color: #000000;
    //   font-size: 14px;
    //   font-weight: 500;
    //   line-height: 14px;
    // }
    .header-desc {
      color: #409eff;
      display: flex;
      align-items: center;
      span {
        margin-left: 4px;
      }
    }
  }
  .header-menu-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow-x: auto;
    flex-shrink: 0;
    height: 60px;
    gap: 40px;
    user-select: none;
    position: relative;
    .menu-item {
      height: 60px;
      font-weight: 400;
      cursor: pointer;
      position: relative;
      flex-shrink: 0;
      font-size: 14px;
      line-height: 60px;
      cursor: pointer;
      color: #666666;
      .menu-item-children {
        height: 0;
        text-align: center;
        overflow: hidden;
        flex-direction: column;
        background-color: #fff;
        box-sizing: border-box;
        transition: all 0.3s;
        left: -5px;
        position: absolute;
        top: calc(100% - 10px);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .menu-item-child {
          width: 100%;
          height: 32px;
          min-width: 104px;
          background: #fff;
          height: 32px;
          font-weight: 400;
          cursor: pointer;
          font-size: 14px;
          box-sizing: border-box;
          padding: 0 10px;
          color: #666666;
          line-height: 32px;
          display: flex;
          justify-content: center;
        }
        .menu-item-child-active {
          width: 100%;
          min-width: 104px;
          height: 32px;
          background: #edf6ff;
          color: #0079f3;
          display: flex;
          justify-content: center;
        }
      }
      &:hover {
        .menu-item-children {
          height: max-content;
          padding: 4px 0;
        }
      }
    }

    .menu-item-title-active {
      color: #333333;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 3px;
      //   background-color: #0079f3;
      // }
    }
    .active-line {
      position: absolute;
      bottom: 0;
      height: 3px;
      transition: all 0.3s;
      background-color: #0079f3;
    }
  }
  .header-action {
    display: flex;
    width: 175px;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
