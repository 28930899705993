<template>
  <div>
    <span class="el-dropdown-link" v-if="!loginInfo" @click="handleLogin">
      登录
    </span>
    <el-dropdown v-else @command="handleCommand">
      <span class="el-dropdown-link">
        {{ loginInfo.nickName || loginForm.userName
        }}<i class="el-icon-caret-bottom el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      :close-on-click-modal="false"
      class="login-dialog"
      title="登录"
      width="400px"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <div style="background-color: #fff; border-radius: 8px; overflow: hidden">
        <div class="login-header"></div>
        <div class="login-form">
          <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="loginForm.username" placeholder="用户名">
                <template slot="prepend">账号</template>
              </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                placeholder="密码"
              >
                <template slot="prepend">密码</template>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="login-btns">
            <div>
              <el-button :loading="loading" type="primary" @click="login"
                >登录</el-button
              >
            </div>
            <div>
              <el-button type="text" @click="handleCancle">取消</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { loginMember, validateUser } from "@/api/index";

export default {
  name: "RightContent",
  data() {
    return {
      loginInfo: null,
      dialogVisible: false,
      loading: false,
      loginForm: {},
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    const loginInfo = localStorage.getItem("taxBaseLoginInfo");
    if (loginInfo) {
      const _loginInfo = JSON.parse(loginInfo);
      if (_loginInfo.token) {
        this.loginInfo = _loginInfo;
        this.handleValidateUser(_loginInfo.token);
      }
    }
  },
  watch: {
    "$store.state.user.showLogin": function (val) {
      this.dialogVisible = val;
    },
  },
  methods: {
    async handleValidateUser(token) {
      try {
        const formData = new FormData();
        formData.append("token", token);
        await validateUser(formData);
        this.$store.dispatch("user/setUserInfo", this.loginInfo);
      } catch (error) {
        localStorage.removeItem("taxBaseLoginInfo");
        this.loginInfo = null;
        this.$message.error("登录已过期，请重新登录");
      }
    },
    handleCommand(command) {
      if (command === "logout") {
        localStorage.removeItem("taxBaseLoginInfo");
        this.loginInfo = null;
        this.$store.dispatch("user/setUserInfo", null);
        // window.location.reload();
      }
    },
    handleCancle() {
      if (!this.loading) {
        this.$store.dispatch("user/setHideLogin");
        this.loginForm = {};
        this.$refs.loginForm.resetFields();
      }
    },
    handleLogin() {
      this.$store.dispatch("user/setShowLogin");
    },
    login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const formData = new FormData();
            formData.append("username", this.loginForm.username);
            formData.append("password", this.loginForm.password);
            const res = await loginMember(formData);
            this.loginInfo = {
              userName: res.userName,
              token: res.token,
              userId: res.userId,
              nickName: res.nickName,
              memberId: res.memberId,
            };
            localStorage.setItem(
              "taxBaseLoginInfo",
              JSON.stringify(this.loginInfo)
            );
            this.$store.dispatch("user/setUserInfo", this.loginInfo);
            this.$message.success("登录成功");
            this.$store.dispatch("user/setHideLogin");
            // window.location.reload();
            // eslint-disable-next-line no-empty
          } catch (error) {}
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  &:hover {
    color: #0079f3;
  }
}
.el-icon-arrow-down {
  font-size: 14px;
}
.login-header {
  width: 400px;
  height: 90px;
  background-color: #0079f3;
  background-image: url("~@/assets/images/login-header.png");
  background-size: cover;
}
.login-dialog {
  /deep/.el-dialog {
    background: none;
    box-shadow: none;
  }
  /deep/.el-dialog__header {
    display: none;
  }
  /deep/.el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
  .login-form {
    padding: 40px 50px 20px 50px;
    box-sizing: border-box;
    /deep/.el-form-item__label {
      display: none;
    }
    /deep/.el-input__inner {
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #e4e4e4;
    }
    /deep/.el-input-group__prepend {
      background: #ffffff;
    }
    /deep/.el-form-item__error {
      left: 70px;
    }
    /deep/.el-form-item {
      margin-bottom: 16px;
    }
    .login-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
      }
      /deep/ .el-button--primary {
        width: 100%;
        height: 40px;
        background: #0079f3;
        border-radius: 2px;
        margin-top: 14px;
      }
      /deep/.el-button--text {
        font-weight: 400;
        font-size: 14px;
        color: #888888;
      }
    }
  }
}
</style>
