import axios from "axios";
import { Message } from "@xt-fe/xt-components";
// import { exchangeToken } from '@/apis/common/index'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 40000,
});

/* 防抖置换token */
// const handleExchangeToken = async () => {
//   try {
//     const formData = new FormData()
//     formData.append('token', getLocalToken())
//     formData.append('ucode', getUserInfo().ucode)
//     const res = await exchangeToken(formData)
//     if (res.token) {
//       setLocalToken(res.token)
//       localStorage.setItem('timestamp', new Date().getTime())
//     }
//     return Promise.resolve()
//   } catch (error) {
//     /* 处理错误情况 */
//     return Promise.reject()
//   }
// }

// request拦截器
service.interceptors.request.use(
  (config) => {
    const loginInfo = localStorage.getItem("taxBaseLoginInfo");
    if (loginInfo) {
      const _loginInfo = JSON.parse(loginInfo);
      if (_loginInfo.token) {
        config.headers["Token"] = _loginInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers["Loginname"] = _loginInfo.userName; // 让每个请求携带自定
      }
    }
    // // 是否需要设置 token
    // const nonToken = (config.headers || {}).isToken === false;
    // // 是否需要防止数据重复提交
    // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    // if (getToken() && !nonToken) {

    //   // CMS当前站点
    //   if (cache.local.get("CurrentSite")) {
    //     config.headers["CurrentSite"] = cache.local.get("CurrentSite");
    //   }
    // }
    // // 语言环境
    // config.headers["Accept-Language"] = i18n.locale;
    // // get请求映射params参数
    // if (config.method === "get" && config.params) {
    //   let url = config.url + "?" + tansParams(config.params);
    //   url = url.slice(0, -1);
    //   config.params = {};
    //   config.url = url;
    // }
    // if (
    //   !isRepeatSubmit &&
    //   (config.method === "post" || config.method === "put")
    // ) {
    //   const requestObj = {
    //     url: config.url,
    //     data:
    //       typeof config.data === "object"
    //         ? JSON.stringify(config.data)
    //         : config.data,
    //     time: new Date().getTime(),
    //   };
    // const sessionObj = cache.session.getJSON("sessionObj");
    // if (
    //   sessionObj === undefined ||
    //   sessionObj === null ||
    //   sessionObj === ""
    // ) {
    //   cache.session.setJSON("sessionObj", requestObj);
    // } else {
    //   const s_url = sessionObj.url; // 请求地址
    //   const s_data = sessionObj.data; // 请求数据
    //   const s_time = sessionObj.time; // 请求时间
    //   const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
    //   if (
    //     s_data === requestObj.data &&
    //     requestObj.time - s_time < interval &&
    //     s_url === requestObj.url
    //   ) {
    //     const message = i18n.t("Common.RepeatSubmit");
    //     console.warn(`[${s_url}]: ` + message);
    //     return Promise.reject(new Error(message));
    //   } else {
    //     cache.session.setJSON("sessionObj", requestObj);
    //   }
    // }
    // }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const { data: { code, data, msg } = {} } = response;

    // eslint-disable-next-line eqeqeq
    if (code === 200) {
      return data;
      // eslint-disable-next-line eqeqeq
    } else {
      Message.error(msg || "请求失败");
      return Promise.reject(response.data);
    }
  },
  (e) => {
    const { status } = e.response || {};
    if (status === 404) {
      Message.error("请求404");
      // return store.dispatch('user/fedLogout')
    }
    if (status === 500) {
      Message.error("请求500服务器异常");
      // return store.dispatch('user/fedLogout')
    }
    /* 处理接口超时 */
    if (e.message.includes("timeout")) {
      Message.error("请求超时");
    }
    return Promise.reject(e);
  }
);

export default service;
