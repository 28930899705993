import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import Element from "element-ui";
import XTComponents from "@xt-fe/xt-components";
// import tippy from "tippy.js";
import "element-ui/lib/theme-chalk/index.css";
import "@xt-fe/xt-components/lib/theme/index.css";
import "./styles/index.css";

const icons = require.context("./assets/icons", false, /\.svg$/);
for (const key of icons.keys()) {
  icons(key);
}

Vue.use(Element, { size: "small", zIndex: 3000 });
Vue.use(XTComponents);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
