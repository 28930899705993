import request from "@/utils/request";

// 项目列表
export function getTags() {
  return request({
    url: "/cmsCatalog/getTags",
    method: "get",
  });
}
export function getCmsCatalogList() {
  return request({
    url: "/cmsCatalog/getCmsCatalogList",
    method: "get",
  });
}
export function getNewCmsCatalogList() {
  return request({
    url: "/cmsCatalog/getNewCmsCatalogList",
    method: "get",
  });
}
export function getCmsContentList(data) {
  return request({
    url: "/cmsCatalog/getCmsContentList",
    method: "post",
    data,
  });
}
export function getCmsContentDetail(data) {
  return request({
    url: "/cmsCatalog/getCmsContentDetail",
    method: "post",
    data,
  });
}
export function getCaseList(data) {
  return request({
    url: "/cmsCatalog/getCaseList",
    method: "post",
    data,
  });
}
export function loginMember(data) {
  return request({
    url: "/login/loginMember",
    method: "post",
    data,
  });
}
export function validateUser(data) {
  return request({
    url: "/login/validate",
    method: "post",
    data,
  });
}
