import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home/index",
  },
  {
    path: "/home/index",
    name: "HomeIndex",
    component: () => import("@/views/home/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    meta: { title: "税收数字化能力" },
    name: "Tax",
    path: "/tax",
    redirect: "/tax-area",
  },
  {
    meta: { title: "区域财源治理" },
    name: "Tax",
    path: "/tax-area",
    component: () => import("@/views/tax/index.vue"),
  },
  {
    meta: { title: "税收现代化" },
    name: "Tax",
    path: "/tax-modern",
    component: () => import("@/views/tax/index.vue"),
  },
  {
    meta: { title: "产业招商" },
    name: "Tax",
    path: "/tax-investment",
    component: () => import("@/views/tax/index.vue"),
  },
  {
    meta: { title: "数据资产入表" },
    name: "Tax",
    path: "/tax-data",
    component: () => import("@/views/tax/index.vue"),
  },
  {
    meta: { title: "案例数据资源" },
    name: "Case",
    path: "/case",
    redirect: "/case-law",
  },
  {
    meta: { title: "政策法规库" },
    name: "Case",
    path: "/case-law",
    component: () => import("@/views/case/case-law/index.vue"),
  },
  {
    meta: { title: "政策法规库详情" },
    name: "CaseLawDetail",
    path: "/case-law-detail",
    component: () => import("@/views/case/case-law/policy/detail.vue"),
  },
  {
    meta: { title: "实务案例库" },
    name: "Case",
    path: "/case-practice",
    component: () => import("@/views/case/case-practice/index.vue"),
  },
  {
    meta: { title: "行业数据库" },
    name: "Case",
    path: "/case-industry",
    component: () => import("@/views/case/case-industry/index.vue"),
  },
  {
    meta: { title: "数据工具箱" },
    name: "Tool",
    path: "/tool",
    component: () => import("@/views/toolbox/index.vue"),
  },
  {
    meta: { title: "课程活动" },
    name: "Course",
    path: "/course",
    component: () => import("@/views/course/index.vue"),
  },
  {
    meta: { title: "联盟合作" },
    name: "Alliance",
    path: "/alliance",
    component: () => import("@/views/alliance/index.vue"),
  },
  {
    meta: { title: "专家团队" },
    name: "Expert",
    path: "/expert",
    component: () => import("@/views/expert/index.vue"),
  },
  {
    meta: { title: "详情", keepAlive: false },
    path: "/detail/:path/:cateId/:contentId",
    component: () => import("@/views/article-detail/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_PATH,
  routes,
});
// 注册 beforeEach 钩子函数
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title}-税收数字化实训基地`;
  } else {
    document.title = "税收数字化实训基地";
  }
  next();
});
export default router;
