const user = {
  namespaced: true,
  state: {
    showLogin: false,
    userInfo: null,
  },
  mutations: {
    showLogin(state) {
      state.showLogin = true;
    },
    hideLogin(state) {
      state.showLogin = false;
    },
    userInfo(state, payload) {
      state.userInfo = payload;
    }
  },
  actions: {
    setShowLogin: ({ commit }) => {
      commit("showLogin");
    },
    setHideLogin: ({ commit }) => {
      commit("hideLogin");
    },
    setUserInfo: ({ commit }, payload) => {
      commit("userInfo", payload);
    }
  },
  getters: {},
};

export default user;
